<template>
  <div>
    <div class="modal-service" v-if="mode === 'description'">
      <div class="modal-service__main">
        <BlockServiceAttrs
          :service="requestDetails"
        ></BlockServiceAttrs>
        <v-dialog v-model="dialogCompleteWork">
          <template v-slot:activator="{ on, attrs }">
            <button
              type="button"
              class="btn btn--prime"
              v-on="on"
              v-bind="attrs"
              :disabled="loading || checkIsRequestCompleted"
              v-if="
                checkIsOwnerResponse &&
                [$ENUM.REQUEST_STATUSES.ACTIVE, $ENUM.REQUEST_STATUSES.IN_WORK].includes(request.status)
              ">
              <template v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </template>
              <template v-else>Завершить работу</template>
            </button>
          </template>
          <v-card>
            <div class="btn-close" @click="dialogCompleteWork = false">
              <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
            </div>
            <ModalCompleteWork
              :loading="loading"
              @submit="photos => onEventWithWork('complete', photos)"
            />
          </v-card>
        </v-dialog>
      </div>
    </div>
    <v-form v-else class="modal-service" v-model="valid">
      <div class="modal-service__main">
        <template v-if="isEditable">
          <div class="modal-service__title">Редактировать</div>
          <div class="modal-service__text">Вы можете внести изменения</div>
        </template>
        <BlockServiceAttrs :service="requestDetails"> </BlockServiceAttrs>

        <div class="field-ell--inline">
          <TextInput
            label="В какое время"
            v-model="formData.time"
            v-mask="`##:##`"
            required
            :disabled="!isEditable"
          />
        </div>

        <div class="field-ell--hidden">
          <AddressAutocomplete
            label="Адрес места оказания услуги"
            v-model="formData.address"
            required
            :disabled="!isEditable"
          ></AddressAutocomplete>
        </div>

        <template v-if="isEditable">
          <button
            :disabled="isBtnDisabled"
            type="button"
            class="btn btn--prime"
            @click="save"
          >Сохранить</button>

          <button
            type="button"
            class="btn btn--red"
            @click="onEventWithWork('cancel')"
          >Отменить заявку</button>
        </template>
<!--    TODO: Оценка дочерней заявки    -->
<!--        <template v-else-if="isFinished && !request.is_rate">-->
<!--          <button-->
<!--            type="button"-->
<!--            class="btn btn&#45;&#45;border-acs"-->
<!--            :disabled="loading"-->
<!--            @click="onRateWork"-->
<!--          >-->
<!--            <template v-if="loading">-->
<!--              <v-progress-circular-->
<!--                indeterminate-->
<!--                color="primary"-->
<!--              ></v-progress-circular>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              Оценить работу-->
<!--            </template>-->
<!--          </button>-->
<!--        </template>-->
        <template v-else-if="isCompleted">
          <button
            type="button"
            class="btn btn--border-acs"
            :disabled="loading"
            @click="onEventWithWork('finish')"
          >
            <template v-if="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </template>
            <template v-else>
              Принять выполненную работу
            </template>
          </button>
        </template>
      </div>
    </v-form>
  </div>
</template>

<script>
import TextInput from '@component/UI/Form/TextInput';
import { mapActions, mapState } from 'vuex';
import AddressAutocomplete from '@component/UI/Form/AddressAutocomplete';
import BlockServiceAttrs from '@component/Module/BlockServiceAttrs.vue';
import ModalCompleteWork from '@component/Modal/ModalCompleteWork';

const createRequest = (initialRequest, formData) => {
  const request = {
    id: initialRequest.id,
  };
  if (formData.address.address) {
    const address = { ...formData.address, family: initialRequest.address.family };
    request.address = address;
  }
  if (formData.time) {
    request.time = formData.time;
  }
  return request;
};
export default {
  components: {
    AddressAutocomplete,
    BlockServiceAttrs,
    ModalCompleteWork,
    TextInput,
  },
  props: {
    checkIsOwnerResponse: {
      type: Boolean,
      default: false
    },
    resolve: {
      type: Function,
      default: () => false
    },
    request: {
      type: Object,
      required: true,
    },
    parentRequestId: {
      type: [String, Number],
      required: true,
    },
    mode: {
      type: String,
      default: 'edit',
      validator(value) {
        return ['edit', 'description'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    isEditable() {
      return this.$ENUM.REQUEST_STATUSES.ACTIVE === this.request.status;
    },
    isCompleted() {
      return this.$ENUM.REQUEST_STATUSES.COMPLETED === this.request.status;
    },
    isFinished() {
      return this.$ENUM.REQUEST_STATUSES.FINISHED === this.request.status;
    },
    isBtnDisabled() {
      return !this.formData.address.address && !this.formData.time;
    },
    checkIsRequestCompleted() {
      const { date, time, hours_per_day } = this.request;
      const selectDate = DateTime(`${date} ${time}`, 'DD.MM.YYYY HH:mm:ss')
        .add(hours_per_day, 'hour');
      const currentDate = DateTime();
      return selectDate.diff(currentDate) > 0;
    },
    requestDetails() {
      return {
        start_date: this.request.start_date,
        end_date: this.request.end_date,
        date: this.request.date,
        hours_per_day: this.request.hours_per_day,
        price: this.request.price,
      };
    },
  },
  data() {
    return {
      dialogCompleteWork: false,
      modalCompleteWorkLoading: false,
      valid: false,
      loading: false,
      formData: {
        address: null,
        time: '',
      },
    };
  },
  watch: {
    request: {
      handler() {
        this.formData.address = this.request.address;
        this.formData.time = this.request.time.slice(0, 5);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('user', ['updateChildServiceRequest']),
    async onEventWithWork(eventName, photos = []) {
      if (!eventName) return eventName;
      eventName = eventName[0].toUpperCase() + eventName.slice(1);
      this.loading = true;
      await new Promise(resolve => {
        this.$emit(`on${eventName}Work`, {
          id: this.request.id,
          done: resolve,
          photos
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    async save() {
      const request = createRequest(this.request, this.formData);

      await this.updateChildServiceRequest({
        childServiceRequest: request,
        parentServiceRequestId: this.parentRequestId,
      });
      await this.resolve({ ...this.request, ...this.formData});
      this.$emit('success-child-update');
    },
    async cancel() {
      await this.updateChildServiceRequest({
        childServiceRequest: { id: this.request.id, status: this.$ENUM.REQUEST_STATUSES.CANCELED },
        parentServiceRequestId: this.parentRequestId,
      });
      await this.resolve({ ...this.request, status: this.$ENUM.REQUEST_STATUSES.CANCELED });
      this.$emit('success-child-update');
    },
  }
};
</script>

<style lang="scss" scoped>


::v-deep {
  .v-input {
    .v-select__selections {
      font-size: 19px;
    }
  }
}

.modal-service__main {
  padding: 45px 26px 34px 26px;
}

</style>
