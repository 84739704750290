export default {
  props: {
    rules: {
      type: Array,
      default: () => [],
    },

    required: {
      type: Boolean,
      default: false,
    },

    onlyLetters: {
      type: Boolean,
      default: false,
    },

    onlyNumbers: {
      type: Boolean,
      default: false,
    },

    max: Number,

    min: Number,

    regular: {
      type: Object,
      default: () => ({
        value: false,
      }),
    },

    isTime: {
      type: Boolean,
      default: false
    },

    isEmail: {
      type: Boolean,
      default: false
    },

    isInn: {
      type: Boolean,
      default: false
    },

    isOgrnOrOgrnip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ruleList: {
        required: v => !!v || 'Обязательное поле',
        onlyLetters: v => /^[a-za-яё]*$/i.test(v) || 'Только буквы',
        onlyNumbers: v => /^[0-9]*$/i.test(v) || 'Толко числа',
        min: v => (v || '').length >= this.min || `Минимальное число символов: ${this.min}`,
        max: v => (v || '').length <= this.max || `Максимальное число символов: ${this.max}`,
        isTime: v => {
          if (v.length === 5) {
            const [hours, minutes] = v.split(':');
            if (Number(hours) < 24 && Number(minutes) < 60) {
              return true;
            }
          }
          return 'Некорректное значение';
        },
        isEmail: v => {
          const pattern  = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || 'Введите корректный e-mail';
        },
        isOgrnOrOgrnip: v => {
          let result = false;
          let message = '';
          if (typeof v === 'number') {
            v = v.toString();
          } else if (typeof v !== 'string') {
            v = '';
          }
          if ([13, 15].indexOf(v.length) === -1) {
            message = 'ОГРН/ОГРНИП может состоять только из 13 или 15 цифр';
          } else {
            let n13 = parseInt((parseInt(v.slice(0, -1)) % 11).toString().slice(-1));
            let n15 = parseInt((parseInt(v.slice(0, -1)) % 13).toString().slice(-1));
            if (n13 === parseInt(v[12])) {
              result = true;
            } else if  (n15 === parseInt(v[14])) {
              result = true;
            } else {
              message = 'Неправильное контрольное число';
            }
          }
          return result || message;
        },
        isInn: inn => {
          let result = false;
          let message = '';
          if (typeof inn === 'number') {
            inn = inn.toString();
          } else if (typeof inn !== 'string') {
            inn = '';
          }
          if ([10, 12].indexOf(inn.length) === -1) {
            message = 'ИНН может состоять только из 10 или 12 цифр';
          } else {
            let checkDigit = function (inn, coefficients) {
              let n = 0;
              for (let i in coefficients) {
                n += coefficients[i] * inn[i];
              }
              return parseInt(n % 11 % 10);
            };
            let n10, n11, n12;
            switch (inn.length) {
              case 10:
                n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if (n10 === parseInt(inn[9])) {
                  result = true;
                }
                break;
              case 12:
                n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
                  result = true;
                }
                break;
            }
            if (!result) {
              message = 'Неправильное контрольное число';
            }
          }
          return result || message;
        }
      }
    };
  },
  computed: {
    activeRules() {
      const ruleList = Object.entries(this.ruleList).reduce((rules, [key, value]) => {
        if (this.$props?.[key]) {
          rules.push(value);
        }
        return rules;
      }, []);


      return [
        ...ruleList,
        ...this.rules
      ];
    }
  },
};
