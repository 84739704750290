<template>
  <div class="modal-complete">
    <div class="modal-complete__title">Подтвердите действие</div>
    <BlockServiceAttrs :service="service"></BlockServiceAttrs>

    <button type="button" class="btn btn--prime" @click="submit">
      <v-progress-circular v-if="loading" indeterminate color="#FF7A78"></v-progress-circular>
      <template v-else>Откликнуться</template>
    </button>
  </div>
</template>

<script>
// import formMixin from '@mixin/form-validation-preload';
import BlockServiceAttrs from '@component/Module/BlockServiceAttrs.vue';

export default {
  components: {
    BlockServiceAttrs,
  },
  // mixins: [formMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      formData: {
        comment: '',
      },
    };
  },
  methods: {
    submit() {
      // this.$refs.upload.submit();
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>

// modal-complete
.modal-complete {
  width: 500px;
  max-width: 500px;
  padding: 37px 35px 30px;
  .modal-complete__title {
    margin-bottom: 10px;
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    
  }
  .modal-complete__text {
    max-width: 365px;
      margin: 0 auto;
    padding-bottom: 16px;
    margin-bottom: 14px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 17px;
    text-align: center;
    border-bottom: 1px solid #cccccc;
  }
  .modal-complete__upload {
    padding-top: 15px;
    margin-bottom: 30px;
    .modal-complete__label {
      margin-bottom: 20px;
      color: #000000;
      font-size: 19px;
    }
  }
  .btn {
    width: 100%;
    max-width: 343px;
    margin: 0 auto;
  }
}

</style>
