<template>
  <div class="help-need-person panel-request__block">
    <h3 class="panel-request__title">Нуждается в помощи</h3>
    <div class="avatar-block">
      <BlockPerson :person="persone.personeAvatar" />
    </div>
    <div class="attrs-row">
      <div class="panel-request__item attrs-row__item">
        <div class="panel-request__field">
          <div class="panel-request__field-prop">Дата рождения</div>
          <div class="panel-request__field-value">{{ persone.birthday }}</div>
        </div>
      </div>
      <div class="panel-request__item attrs-row__item">
        <div class="panel-request__field">
          <div class="panel-request__field-prop">Вес</div>
          <div class="panel-request__field-value">{{ persone.weight }} кг</div>
        </div>
      </div>
    </div>
    <div class="attrs-row">
      <div class="panel-request__item attrs-row__item">
        <div class="panel-request__field">
          <div class="panel-request__field-prop">Диагноз</div>
          <div class="panel-request__field-value">{{ persone.reason }}</div>
        </div>
      </div>
      <div class="panel-request__item attrs-row__item">
        <div class="panel-request__field">
          <div class="panel-request__field-prop">Адрес</div>
          <div class="panel-request__field-value">{{ service.address.address }}</div>
        </div>
      </div>
    </div>

    <BlockService
      @edit-child="openDialogChildRequest"
      :is-editable="false"
      :service="service"
    />

    <v-dialog v-model="childRequestModal">
      <v-card>
        <div class="btn-close" @click="childRequestModal = false">
          <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
        </div>
        <ModalChildRequest
          mode="description"
          v-if="childRequestModal"
          :parentRequestId="service.id"
          :request="selectedChildRequest"
          :checkIsOwnerResponse="checkIsOwnerResponse"
          @onCompleteWork="onCompleteChildRequest"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BlockPerson from '@component/Module/BlockPerson';
import BlockService from '@component/Module/BlockService';
import ModalChildRequest from '@component/Modal/ModalChildRequest';
import { mapState } from 'vuex';

export default {
  components: {
    BlockPerson,
    BlockService,
    ModalChildRequest,
  },
  props: {
    persone: {
      /*  personeAvatar
          birthday
          weight
          reason
          */
      type: Object,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
    checkIsOwnerResponse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      childRequestModal: false,
      selectedChildRequest: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    })
  },
  methods: {
    async onCompleteChildRequest({ id, done, photos }) {
      const accepted = this.service?.accepted_response;
      if (accepted) {
        await RequestManager.Service.completeWork(
          this.user.id,
          id,
          accepted.id,
          { image: photos }
        ).then(() => {
          done();
          this.childRequestModal = false;
          this.$emit('changeStatusChildRequest', { id, status: 'CPD' });
        });
      }
    },
    openDialogChildRequest(childId) {
      this.selectedChildRequest = this.service.child.find(el => el.id === childId);
      this.childRequestModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.help-need-person {
  .attrs-row {
    border: 0;
  }
}
.panel-request__title {
  margin-bottom: 19px;
  color: #020202;
  font-size: 17px;
  font-weight: 600;
}

.panel-request__block {
  margin-bottom: 40px;
  // &:last-child {
  //   margin-bottom: 0;
  // }
  .panel-request__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(163, 163, 163, 0.3);
  }
}


.panel-request__field {
  padding: 11px 0;
  .panel-request__field-prop {
    margin-bottom: 6px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 15px;
  }
  .panel-request__field-value {
    color: #000000;
    font-size: 17px;
  }
}

.attrs-row{
  display: flex;
  justify-content:flex-start;
  flex-direction: column;
}
.attrs-row__item{
  width: 49%;
}

.avatar-block{
  border-bottom: 1px solid rgba(163, 163, 163, 0.3);
}
.block-service{
  margin-top: 25px;
}
.panel-request__item {
  width: 100%
}

@include respond-to('md') {
  .attrs-row{
    justify-content: space-between;
    flex-direction: row;
  }
  .panel-request__item {
   width: 49%
  }
  .avatar-block{
    padding-bottom: 25px;

  }
}





</style>
