<template>
  <div>
    <FiltersOrNavBtns :list="navList" v-bind="$attrs" v-on="$listeners"></FiltersOrNavBtns>
  </div>
</template>

<script>
import FiltersOrNavBtns from '@component/Module/FiltersOrNavBtns.vue';
import { ASSISTANCE_PROVIDERS_TYPES } from '@enum/variable';

import { FILTERS_VALUES } from './enums';
export default {
  inheritAttrs: false,
  components: {
    FiltersOrNavBtns,
  },
  props: {
    provider: {
      type: String,
      required: true,
      validator(value) {
        return Object.keys(ASSISTANCE_PROVIDERS_TYPES).indexOf(value) !== -1;
      },
    },
  },

  data() {
    const navList = [
      {
        label: 'Все заявки',
        icon: require('@img/icons/app-new-provide.svg'),
        value: FILTERS_VALUES.ALL,
      },
      {
        label: 'Заявки в работе',
        icon: require('@img/icons/app-active-provide.svg'),
        value: FILTERS_VALUES.IN_WORK,
      },
      {
        label: 'Отклики',
        icon: require('@img/icons/app-responses-provide.svg'),
        value: FILTERS_VALUES.RESPONSESE,
      },
      {
        label: 'Выполненные',
        icon: require('@img/icons/app-completed-provide.svg'),
        value: FILTERS_VALUES.COMPLETED,
      },
    ];

    const applicationsListRoute =
      this.provider === this.$ENUM.ASSISTANCE_PROVIDERS_TYPES.PERSONE
        ? this.$routeName.ASSISTANCE_PROVIDE_APPLICATIONS
        : this.$routeName.ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS;
    navList.map(el => {
      el.name = applicationsListRoute;
    });

    return {
      navList,
    };
  },
};
</script>

