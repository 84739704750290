<template>
  <div class="modal-support">
    <div class="modal-support__title">Техническая поддержка</div>
    <div class="modal-support__text">Возникли вопросы? Заполните форму обратной связи</div>
    <TextInput
      label="Опишите свою проблему"
      :value="formData.comment"
      @update="update('comment', $event)"
    />
    <div class="modal-support__upload">
      <div class="modal-support__label">Добавьте фотографии выполненной работы</div>
      <el-upload
        ref="upload"
        action="https://jsonplaceholder.typicode.com/posts/"
        :auto-upload="false"
        list-type="picture-card"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
    <button type="button" class="btn btn--prime" @click="submitUpload">Отправить</button>
  </div>
</template>

<script>

import formMixin from '@mixin/form-validation-preload';
import TextInput from "@component/UI/Form/TextInput";

export default {
  components: {
    TextInput,
  },
  mixins: [formMixin],
  data() {
    return {
      formData: {
        comment: '',
      }
    };
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    }
  }
};
</script>

<style lang="scss" scoped>

// modal-support
.modal-support {
  width: 100%;
  max-width: 400px;
  padding: 45px 28px 22px;
  .modal-support__title {
    margin-bottom: 10px;
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
  }
  .modal-support__text {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 17px;
    text-align: center;
  }
  .modal-support__upload {
    padding-top: 15px;
    margin-bottom: 30px;
    .modal-support__label {
      margin-bottom: 20px;
      color: #000000;
      font-size: 19px;
    }
  }
  .btn {
    width: 100%;
  }
}

</style>
