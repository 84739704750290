<template>
  <div class="help-request-person page-content">
    <div class="container">
      <FiltersBtns :provider="provider" mode="links"></FiltersBtns>
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div v-if="loading">
        <v-progress-linear indeterminate></v-progress-linear>
      </div>

      <div v-else class="panel-request">
        <ApplicationContent
          @take-work="loadRequest"
          @changeStatusChildRequest="changeStatusChildRequest"
          :request="request"
          :provider="provider"
          :checkIsOwnerResponse="checkIsOwnerResponse"
        >
          <template #footer>
<!--            TODO: Завершить работу на детальной странице дочерней заявки (не хватает отклика на родительскую заявку)-->
<!--            <div v-if="-->
<!--              [$ENUM.REQUEST_STATUSES.IN_WORK, $ENUM.REQUEST_STATUSES.ACTIVE].includes(request.status) &&-->
<!--              !checkIsOwnerResponse-->
<!--            " class="page-content__btn-gp">-->
<!--              <v-dialog v-model="dialogCompleteWork">-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <button-->
<!--                    v-on="on"-->
<!--                    v-bind="attrs"-->
<!--                    type="button"-->
<!--                    class="btn btn&#45;&#45;prime"-->
<!--                    :disabled="checkIsRequestCompleted"-->
<!--                  >Завершить работу</button>-->
<!--                </template>-->
<!--                <v-card>-->
<!--                  <div class="btn-close" @click="dialogCompleteWork = false">-->
<!--                    <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>-->
<!--                  </div>-->
<!--                  <ModalCompleteWork-->
<!--                    :loading="modalLoading"-->
<!--                    @submit="onCompleteWork"-->
<!--                  />-->
<!--                </v-card>-->
<!--              </v-dialog>-->
<!--            </div>-->
          </template>
        </ApplicationContent>
      </div>
    </div>
    <div class="page-content__footer">
      <v-dialog v-model="dialogSupport">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="page-content__link"
            v-on="on"
            v-bind="attrs"
          >Техническая поддержка</div>
        </template>
        <v-card>
          <div class="btn-close" @click="dialogSupport = false">
            <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
          </div>
          <ModalSupport />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FiltersBtns from '@component/Module/ProviderReqestList/FiltersBtns.vue';

import BlockPersonModel from '@component/Module/BlockPersonModel.js';
import HelpNeedPersonModel from '@component/Module/HelpNeedPersonModel.js';

import ModalSupport from '@component/Modal/ModalSupport';
import ModalCompleteWork from '@component/Modal/ModalCompleteWork';
import ApplicationContent from '@component/Page/LkAssistanceProvideApplication/ApplicationContent.vue';
import { ASSISTANCE_PROVIDERS_TYPES } from '@enum/variable';

export default {
  name: 'ApplicationPageComponent',
  components: {
    FiltersBtns,
    ModalSupport,
    ModalCompleteWork,
    ApplicationContent,
  },
  props: {
    provider: {
      type: String,
      required: true,
      validator(value) {
        return Object.keys(ASSISTANCE_PROVIDERS_TYPES).indexOf(value) !== -1;
      },
    },
  },

  data() {
    const applicationsListRoute =
      this.provider === this.$ENUM.ASSISTANCE_PROVIDERS_TYPES.PERSONE
        ? this.$routeName.ASSISTANCE_PROVIDE_APPLICATIONS
        : this.$routeName.ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS;
    return {
      dialogSupport: false,
      request: null,
      loading: false,
      dialogCompleteWork: false,
      modalLoading: false,
      items: [
        {
          text: 'Все заявки',
          disabled: false,
          exact: true,
          to: { name: applicationsListRoute },
        },
        {
          text: `Заявка № ${this.$route.params.applicationId}`,
          disabled: true,
        },
      ],
    };
  },
  async created() {
    await this.loadRequest();
  },
  computed: {
    ...mapState({
      genders: state => state.enums.genders,
      user: state => state.user.user,
    }),
    checkIsRequestCompleted() {
      const { end_date, time, hours_per_day } = this.request;
      const selectDate = DateTime(`${end_date} ${time}`, 'DD.MM.YYYY HH:mm:ss')
        .add(hours_per_day, 'hour');
      const currentDate = DateTime();
      return selectDate.diff(currentDate) > 0;
    },
    checkIsOwnerResponse() {
      return this.acceptedResponse?.user?.id === this.user?.id;
    },
    acceptedResponse() {
      const responseId = this.request?.accepted_response?.id;
      return responseId ? this.request.response.find(response => response.id === responseId) : null;
    },
    helpNeedPerson() {
      return new HelpNeedPersonModel(this.request.family, this.genders);
    },
    helpReqestPerson() {
      return new BlockPersonModel({
        first_name: this.request.creator.first_name,
        last_name: this.request.creator.last_name,
        photo: this.request.creator.photo,
        fullDate: this.request.created_at,
      });
    },
  },
  methods: {
    changeStatusChildRequest({ id, status }) {
      const index = this.request.child.findIndex(request => request.id === id);
      if (index + 1 > 0) {
        this.request.child[index].status = status;
      }
    },
    async onCompleteWork(photos = []) {
      this.modalLoading = true;
      const acceptedResp = this.request.response.find(el => el.status === 'ACP');
      // const acceptedResp = this.request.response[0]; // for test

      await RequestManager.Service.completeWork(this.user.id, this.$route.params.applicationId, acceptedResp.id, {
        image: photos,
      });
      this.request = await RequestManager.WorkRequests.getRequest(this.$route.params.applicationId);

      this.modalLoading = false;
      this.dialogCompleteWork = false;
    },
    async loadRequest() {
      try {
        this.loading = true;
        const request = await RequestManager.WorkRequests.getRequest(this.$route.params.applicationId);
        this.request = request;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep  {
  .v-breadcrumbs__item{
    color: var(--color-prime)
  }
  .v-breadcrumbs li:nth-child(2n){
    padding: 0 6px;
  }
}
.help-request-person {
   border-bottom: 1px solid rgba(163, 163, 163, 0.3);
   margin-bottom: 36px;
}
// panel-request
.panel-request {
  position: relative;
  margin-bottom: 40px;
  padding: 12px 27px 53px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid rgba(118, 118, 128, 0.12);
  .request-status {
    position: absolute;
    top: 12px;
    right: 30px;
  }

  .panel-request__num {
    margin-bottom: 9px;
    color: rgba(2, 2, 2, 0.3);
    font-size: 15px;
    span {
      color: #020202;
    }
  }


}
</style>
