<template>
  <div class="panel-request__block">
    <h3 class="panel-request__title">Исполнитель</h3>

    <div class="panel-request__item">

      <BlockPerson
        class="block-person"
        :person="person"
      />

    </div>

    <div class="panel-request__row">

      <div class="panel-request__item">
        <div class="ic-ell">
          <div class="ic-ell__icon">
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
          </div>
          <div class="ic-ell__text">
            <strong>{{ person.category }}</strong>
          </div>
        </div>
      </div>

      <div class="panel-request__item">
        <div class="ic-ell">
          <div class="ic-ell__icon">
            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
          </div>
          <div class="ic-ell__text">
            <strong>Дата рождения:</strong> {{ person.birthday }}
          </div>
        </div>
      </div>

    </div>

    <div v-if="person.about" class="panel-request__item">
      <div class="panel-request__about">
        <div class="panel-request__label">О себе</div>
        <div class="panel-request__text">{{ person.about }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import BlockPerson from '@component/Module/BlockPerson';

export default {
  components: {
    BlockPerson,
  },
  props: {
    person: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-request__row {
  @include respond-to(md) {
    display: flex;
    width: 100%;
  }
  .panel-request__item {
    width: 100%;
    @include respond-to(md) {
      flex: 1;
    }
    &:nth-of-type(1) {
      @include respond-to(md) {
        margin-right: 28px;
      }
    }
  }
}
.ic-ell {
  // padding: 16px 0;
}
.panel-request__title {
  margin-bottom: 19px;
  color: #020202;
  font-size: 17px;
  font-weight: 600;
}
.panel-request__value {
  color: #0C76DA;
  font-size: 15px;
}
.panel-request__label {
  margin-bottom: 15px;
  color: #000000;
  font-size: 17px;
}
.panel-request__text {
  color: rgba(0, 0, 0, 0.4);
  font-size: 17px;
}
.panel-request__block {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  .panel-request__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(163, 163, 163, 0.3);
  }
}
.panel-request__about {
  padding: 20px 0 10px;
}
.block-person {
  margin-top: 10px;
  margin-bottom: 18px;
}
</style>