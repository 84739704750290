<template>
  <div class="block-services__body" :class="{ 'block-services__body_disabled': disabled }">
    <div class="block-services__block" v-if="service.start_date">
      <div class="block-services__prop">Начало</div>
      <div class="block-services__value block-services__value&#45;&#45;blue">{{ service.start_date | formatDate }}</div>
    </div>

    <div class="block-services__block" v-if="service.end_date">
      <div class="block-services__prop">Конец</div>
      <div class="block-services__value block-services__value&#45;&#45;blue">{{ service.end_date | formatDate }}</div>
    </div>

    <div class="block-services__block" v-else-if="service.date">
      <div class="block-services__prop">Дата</div>
      <div class="block-services__value block-services__value--blue">{{ service.date | formatDate }}</div>
    </div>

    <div class="block-services__block" v-if="service.hours_per_day">
      <div class="block-services__prop">Количество часов в&nbsp;день</div>
      <div class="block-services__value block-services__value--blue">{{ service.hours_per_day }}</div>
    </div>

    <div class="block-services__block" v-if="service.time">
      <div class="block-services__prop">В какое время</div>
      <div class="block-services__value block-services__value--blue">{{ service.time | formatTime }}</div>
    </div>

    <div class="block-services__block" v-if="service.address">
      <div class="block-services__prop">Адрес</div>
      <div class="block-services__value">{{ service.address.address }}</div>
    </div>

    <div class="block-services__block" v-if="service.price">
      <div class="block-services__prop">Сумма вознаграждения</div>
      <div class="block-services__value">{{ service.price }} ₽</div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import formatDate from '@filter/modules/formatDate';

export default {
  filters: {
    formatDate,
  },
  props: {
    service: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>


<style lang="scss" scoped>
 .block-services__body {
    padding: 20px 0 12px;
    // border-top: 1px solid rgba(118, 118, 128, 0.2);
    .block-services__block {
      display: flex;
      margin-bottom: 10px;
      color: #000000;
      font-size: 15px;
      .block-services__prop {
        width: 50%;
        padding-right: 15px;
        transition: color .2s ease-in-out;
      }
      .block-services__value {
        width: 70%;
        transition: color .2s ease-in-out;
        &--blue {
          color: #0C76DA;
        }
      }
    }
    &_disabled{
      .block-services__block {
        color: #B3B3B8;
        .block-services__value {
          &--blue {
            color: #B3B3B8;
          }
        }
      }
    }
  }

</style>
