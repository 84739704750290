<template>
  <div
    :class="[
      'block-service',
      {
        'block-service--open': isOpen,
        'block-service--disabled': !isActive,
        'block-service--collapse': showDetails,
      },
    ]"
  >
    <div class="block-service__head">
      <div class="block-service__title" @click="isOpen = !isOpen">
        <div class="block-service__type">{{ categoryName }}</div>
        <div class="block-service__name">{{ serviceName }}</div>
        <div class="block-service__type" v-if="service.child && service.child.length">
          Общее количество услуг: {{ service.child.length }}
        </div>
      </div>
      <div v-if="isEditable" class="block-service__control">

        <v-switch
          v-model="isActive"
          :loading="loading"
        >
<!--          <template #label>-->
<!--            <v-progress-circular-->
<!--              v-if="loading"-->
<!--              indeterminate-->
<!--              color="primary"-->
<!--              :value="0"-->
<!--              size="24"-->
<!--              class="ml-2"-->
<!--            ></v-progress-circular>-->
<!--          </template>-->
        </v-switch>

        <button type="button" :disabled="!isActive" class="block-service__btn" @click="editService">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block-service__edit'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 14.25L11.063 3.187l3.75 3.75L3.75 18H0v-3.75zM17.719 4.031L15.89 5.86l-3.75-3.75L13.969.281A.954.954 0 0114.672 0c.281 0 .515.094.703.281l2.344 2.344a.954.954 0 01.281.703.954.954 0 01-.281.703z" fill="#0C76DA"/></svg>
        </button>

        <button type="button" :disabled="!isActive" class="block-service__btn" @click="removeService">
          <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block-service__delete'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.984 15.984v-12h12v12c0 .563-.203 1.047-.609 1.454a1.852 1.852 0 01-1.36.562H3a2.069 2.069 0 01-1.453-.563 2.069 2.069 0 01-.563-1.453zm13.032-15V3H0V.984h3.516L4.5 0h5.016l.984.984h3.516z" fill="#FF3431"/></svg>
        </button>
      </div>
    </div>

    <BlockServiceAttrs v-show="isOpen && showDetails" :disabled="!isActive" :service="service">
      <BlockServiceCalendar
        @edit-child="onEditChild"
        v-if="service.child && service.child.length"
        :services="service.child"
      ></BlockServiceCalendar>
    </BlockServiceAttrs>
  </div>
</template>

<script>
import formatDate from '@filter/modules/formatDate';
import BlockServiceCalendar from '@component/Module/BlockServiceCalendar.vue';

import BlockServiceAttrs from '@component/Module/BlockServiceAttrs.vue';
export default {
  name: 'BlockService',
  components: {
    BlockServiceCalendar,
    BlockServiceAttrs,
  },
  filters: {
    formatDate,
  },
  props: {
    service: Object,
    isEditable: {
      type: Boolean,
      default: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      isOpen: this.isEditable ? false : true,
      isOpenChildApplication: false,
    };
  },
  computed: {
    isActive: {
      get() {
        return this.service?.status === 'ACT' || this.service?.is_active || !this.isEditable;
      },
      set(value) {
        new Promise((resolve) => {
          this.loading = true;
          this.$emit('changeStatus', { value, done: resolve });
        }).finally(() => this.loading = false);
      },
    },

    categoryName() {
      return this.service?.category?.name;
    },
    serviceName() {
      return this.service?.responsibility?.name;
    },
  },
  methods: {
    editService() {
      this.$emit('edit', this.service);
    },
    removeService() {
      this.$emit('remove', this.service);
    },
    onEditChild(childId) {
      this.$emit('edit-child', childId);
    },
  },
};
</script>

<style scoped lang="scss">
.child-applications-title {
  margin-top: 1rem;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
}

// block-service
.block-service {
  padding: 0 16px;
  background-color: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.2);
  // border-bottom: none;
  border-radius: 10px;
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid rgba(118, 118, 128, 0.2);
  }
  .block-service__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    .block-service__title {
      margin-right: 20px;
      .block-service__type {
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.4);
        font-size: 17px;
        transition: color .2s ease-in-out;
      }
      .block-service__name {
        color: #000000;
        font-size: 19px;
        transition: color .2s ease-in-out;
      }
    }
    .block-service__control {
      display: flex;
      align-items: center;
      .block-service__btn {
        margin-left: 20px;
        cursor: pointer;
        .block-service__edit {
          path {
            fill: #FF9500;
            transition: fill .2s ease-in-out;
          }
          &:hover {
            path {
              fill: darken(#FF9500, 10%);

            }
          }
        }
        .block-service__delete {
          path {
            fill: #FF3431;
            transition: fill .2s ease-in-out;
          }
          &:hover {
            path {
              fill: darken(#FF3431, 20%);
            }
          }
        }
        &[disabled] {
          cursor: default;
          .block-service__edit {
            path {
              fill: #B3B3B8;
            }
            &:hover {
              path {
                fill: #B3B3B8;

              }
            }
          }
          .block-service__delete {
            path {
              fill: #B3B3B8;
            }
            &:hover {
              path {
                fill: #B3B3B8;
              }
            }
          }
        }
      }
    }
  }
  &--collapse {
    .block-service__head {
      .block-service__title {
        cursor: pointer;
      }
    }
  }
  &--disabled {
    .block-service__head {
      .block-service__title {
        .block-service__type {
          color: #B3B3B8;
        }
        .block-service__name {
          color: #B3B3B8;
        }
      }
    }
  }
  .children-services{
    border-top:  1px solid rgba(118, 118, 128, 0.2);
    .children-services__item{
      padding: 10px 0;
      border-bottom:  1px solid rgba(118, 118, 128, 0.2);
    }
    .children-service__time{
      padding-top: 4px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
    }
    .children-services__title{
      cursor: auto !important;
    }
  }

}

.service-amount {

}

::v-deep {
  .v-progress-circular--indeterminate {
    .v-progress-circular__overlay {
      stroke: var(--color-prime);
    }
  }
}




</style>
