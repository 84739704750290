<template>
  <v-autocomplete
    no-filter
    :loading="loading"
    :items="items"
    v-model="location"
    :search-input.sync="search"
    hide-no-data
    hide-details
    :label="label"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: "AddressAutocomplete",
  props: {
    label: {
      type: String,
      default: 'Адрес'
    },
    disabled: Boolean,
    value: Object
  },
  data() {
    return {
      loading: false,
      coordinates: {},
      search: null,
      searchResults: [],
      autocompleteService: null,
      geocoderService: null,
    };
  },
  computed: {
    location: {
      get() {
        return this.value?.address;
      },
      set(v) {
        this.$emit('input', {
          ...this.value,
          address: v
        });
      }
    },
    items() {
      return this.searchResults.length || !this.value?.address
        ? this.searchResults
        : [this.value.address];
    }
  },
  watch: {
    search (newValue) {
      if (newValue) {
        this.autocompleteService.getPlacePredictions({
          input: newValue,
          types: ['address']
        }, this.displaySuggestions);

        this.coordinates = this.geocoderService.geocode({ address: newValue }, (results, status) => {

          if (status !== "OK") {

            this.coordinates = {};
            return;

          } else {

            const location = results[0].geometry.location;
            this.coordinates = {
              latitude: location.lat(),
              longitude: location.lng()
            };

          }

          this.$emit('input', {
            ...this.value,
            coordinates: this.coordinates
          });

        });
      }
    }
  },
  mounted() {
    this.MapsInit();
  },
  methods: {
    MapsInit () {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.geocoderService = new window.google.maps.Geocoder();
    },
    displaySuggestions (predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }
      this.searchResults = predictions.map(prediction => prediction.description);
    },
  }
};
</script>

<style scoped>

</style>
