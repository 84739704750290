<template>
  <div class="panel-request__block">
    <template v-if="hasExecutors">
      <h3 class="panel-request__title">Выбрать исполнителя</h3>
      <div class="executor-list">
        <template v-for="executor in executors">
          <div class="executor-list__ell" :key="executor.id">
            <CardExecutor
              :is-selected="executor.to_user.id === selectedExecutor"
              :name="executor.info.fullName"
              :role="executor.info.role"
              :photo="executor.info.photo"
              @click="setExecutor(executor)"
            />
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <p class="mb-2">Нет сотрудников для исполнения этой заявки</p>
    </template>
  </div>
</template>

<script>
import CardExecutor from '@component/Module/CardExecutor';
export default {
  components: {
    CardExecutor,
  },
  props: {
    executors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectedExecutor: null,
    };
  },
  computed: {
    hasExecutors() {
      return this.executors?.length;
    }
  },
  methods: {
    setExecutor(executor) {
      this.selectedExecutor = executor.to_user?.id;
      this.$emit('set-executor', this.selectedExecutor);
    },
  },
};
</script>

<style lang="scss">
.panel-request__title {
  margin-bottom: 19px;
  color: #020202;
  font-size: 17px;
  font-weight: 600;
}
.executor-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  .executor-list__ell {
    width: 20%;
    margin-bottom: 10px;
    padding: 0 10px;
  }
}
</style>
