<template>
  <v-dialog width="auto" v-model="dialogTakeWork">
    <template v-slot:activator="{ on, attrs }">
      <button
        :disabled="isUserAlreadyResponse"
        v-on="on"
        v-bind="attrs"
        type="button"
        class="btn btn--prime take-work-btn"
      >
        {{ takeWorkBtnText }}
      </button>
    </template>
    <v-card>
      <div class="btn-close" @click="dialogTakeWork = false">
        <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
      </div>
      <ModalTakeWork :service="request" :loading="modalLoading" @submit="onTakeWork" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import ModalTakeWork from '@component/Modal/ModalTakeWork';

export default {
  components: {
    ModalTakeWork,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    isUserAlreadyResponse: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dialogTakeWork: false,
      modalLoading: false,
    };
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    takeWorkBtnText() {
      return this.isUserAlreadyResponse ? 'Вы уже откликнулись на эту заявку' : 'Откликнуться';
    },
  },
  methods: {
    async onTakeWork(price = 10) {
      this.modalLoading = true;
      await RequestManager.Service.takeWork(this.user.id, this.request.id, {
        price,
        request: this.request.id,
        user: this.user.id,
      });
      this.modalLoading = false;
      this.dialogTakeWork = false;
      this.$emit('take-work-success');
    },
  },
};
</script>

<style lang="scss" scoped>
.take-work-btn{
  width: 100%;
  max-width: 343px;
  margin: 0 auto;
}


</style>
