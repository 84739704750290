<template>
  <div class="modal-complete">
    <div class="modal-complete__title">Завершить работу</div>
    <div class="modal-complete__text">
      Проведите фотофиксацию выполненных работ (не обязательно, может быть полезно при разрешении споров)
    </div>

    <div class="modal-complete__upload">
      <div class="modal-complete__label">Добавьте фотографии выполненной работы</div>
      <el-upload
        multiple
        ref="upload"
        :on-change="onChange"
        :auto-upload="false"
        :on-remove="onRemove"
        list-type="picture-card"
        action="https://jsonplaceholder.typicode.com/posts/"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
    <button type="button" class="btn btn--prime" @click="submit">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="#FF7A78"
      ></v-progress-circular>
      <template v-else>Завершить работу </template>
    </button>
  </div>
</template>

<script>
// import formMixin from '@mixin/form-validation-preload';

export default {
  // mixins: [formMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        comment: '',
      },
      photos: [],
    };
  },
  methods: {
    submit() {
      //      this.formData.photo = files.item(0);
      // this.$refs.upload.submit();
      this.$emit('submit', this.photos);
    },
    onChange(file, fileList) {
      this.photos = fileList.map(el => el.raw);
    },
    onRemove(file, fileList) {
      this.photos = fileList.map(el => el.raw);
    },
  },
};
</script>

<style lang="scss" scoped>

// modal-complete
.modal-complete {
  width: 100%;
  max-width: 484px;
  padding: 37px 35px 30px;
  .modal-complete__title {
    margin-bottom: 10px;
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    text-align: center;

  }
  .modal-complete__text {
    max-width: 365px;
      margin: 0 auto;
    padding-bottom: 16px;
    margin-bottom: 14px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 17px;
    text-align: center;
    border-bottom: 1px solid #cccccc;
  }
  .modal-complete__upload {
    padding-top: 15px;
    margin-bottom: 30px;
    .modal-complete__label {
      margin-bottom: 20px;
      color: #000000;
      font-size: 19px;
    }
  }
  .btn {
    width: 100%;
    max-width: 343px;
    margin: 0 auto;
  }
}

</style>
