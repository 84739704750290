<template>
    <!-- <label class="a-form--label">{{label}}</label> -->
    <!-- <input class="a-form--input" type="text" name="name" @input="$emit('input', $event.target.value)" :value="value" v-bind="$attrs"/> -->
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :type="type"
    :label="label"
    :value="value"
    :rules="activeRules"
    :placeholder="placeholder"
    :name="name"
    :readonly="readonly"
  />
</template>

<script>
import inputValidation from "@mixin/inputValidation";

export default {
  name: 'TextInput',
  inheritAttrs: false,
  mixins: [inputValidation],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: [String, undefined],
      default: undefined,
    },
    placeholder: String,
    label: String,
    value: [String, Number],
    readonly: {
      type: Boolean,
      default: false
    },
  }
};
</script>

<style lang="scss" scoped></style>
