<script>
  export default {
    data() {
      return {
        valid: false
      };
    },
    props: {
      storedFormData: {
        type: Object,
        default: () => ({ noData: true })
      },
      step: String,
      submitButton: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      valid() {
        this.$emit('validate', this.valid);
      }
    },
    mounted() {
      if (!this.storedFormData.noData) {
        this.formData = {...this.storedFormData};
      }

      if (this.storedFormData.photo) {
        this.imgAdd(this.storedFormData.photo);
      }
    },
    methods: {
      update(key, pl) {
        this.formData[key] = pl;
      }
    },
    beforeDestroy() {
      if (!this.submitButton) {
        this.$emit('submit', this.formData);
      } else {
        return false;
      }
    }
  };
</script>