import formatDateString from '@filter/modules/formatDateString.js';
import formatTime from '@filter/modules/formatTime.js';

const getDate = (date, time, fullDate) => {
  const resDateTimeParts = [];
  if (date || time) {
    resDateTimeParts[0] = date && formatDateString(date);
    resDateTimeParts[1] = time && formatTime(time);
  }

  if (fullDate) {
    const dateParts = fullDate.split(' ');
    resDateTimeParts[0] = dateParts[0] && formatDateString(dateParts[0]);
    resDateTimeParts[1] = dateParts[1] && formatTime(dateParts[1]);
  }
  return resDateTimeParts.join(' ').trim();
};
export default class BlockPerson {
  constructor({ first_name, last_name, date, time, fullDate, role, rate, status, gender, photo }, genders) {
    this.name = first_name && last_name
      ? `${first_name} ${last_name}`
      : undefined;

    this.date = getDate(date, time, fullDate);

    this.image =
      photo && /^https?:\/\//.test(photo) ? photo : !photo ? photo : process.env.VUE_APP_SERVER_API_URL + photo;
    this.role = role;
    this.rate = rate;
    this.status = status;
    this.gender = gender && genders.find(el => el.key === gender).value;
  }
}
