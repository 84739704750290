import BlockPersonModel from '@component/Module/BlockPersonModel.js';

export default class HelpNeedPerson {
  constructor(persone, genders) {
    this.personeAvatar = new BlockPersonModel(
      {
        first_name: persone.first_name,
        last_name: persone.last_name,
        gender: persone.gender,
        photo: persone.photo,
      },
      genders
    );
    this.birthday = persone.birthday;
    this.weight = persone.weight;
    this.reason = persone.reason;
  }
}
