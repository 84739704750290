<template>
  <div>
    <div class="top-row">
      <button
        type="button"
        class="child-service-open-btn"
        @click="isOpenChildApplication = isOpenChildApplication === 0 ? undefined : 0"
      >
        <div class="child-service-open-btn__text">Связанные заявки</div>

        <v-icon v-if="isOpenChildApplication === 0"> mdi-chevron-up </v-icon>
        <v-icon v-else> mdi-chevron-down</v-icon>
      </button>
      <v-slide-y-transition>
        <Select
          v-show="isOpenChildApplication === 0"
          class="month-select"
          :items="servicesMonths"
          item-value="value"
          item-text="name"
          v-model="selectedMonth"
          prepend-inner-icon="mdi-calendar"
        >
        </Select>
      </v-slide-y-transition>
    </div>

    <v-expansion-panels v-model="isOpenChildApplication">
      <v-expansion-panel class="slide-panel">
        <v-expansion-panel-content class="slide-content">
          <v-calendar
            class="calendar"
            :event-height="55"
            event-text-color="#fff"
            :short-months="false"
            locale="ru"
            ref="calendar"
            v-model="selectedMonth"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            type="month"
            :events="events"
            event-overlap-mode="stack"
            :event-overlap-threshold="90"
            @change="getEvents"
            :event-more="false"
            @click:event="onClickEvent"
          >
            <template #day-label="{ day }">
              <span class="calendar-label">
                {{ day }}
              </span>
            </template>
            <template #event="{ event, eventParsed }">
              <div
                :class="[
                  'event',
                  `event--${event.service.status}`
                ]"
              >
                <div class="event__time">
                  {{ eventParsed.start.time }}
                </div>
                <div class="event__name">
                  {{ event.name }}
                </div>
              </div>
            </template>
          </v-calendar>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Select from '@component/UI/Form/Select.vue';
const getMonth = date => {
  return +date.split('.')[1];
};

export default {
  components: {
    Select,
  },
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      events: [],
      selectedMonth: '2021-05-01',
      isOpenChildApplication: 0,
      // panel: false,
    };
  },
  watch: {
    services() {
      this.getEvents({
        start: {
          month: +this.selectedMonth.split('-')[1],
        },
      });
    },
  },
  computed: {
    servicesMonths() {
      const MONTHS = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Ноябрь',
        'Декабрь',
      ];

      const groupByMonthAndYear = {};

      this.services.forEach(service => {
        const dateParts = service.date.split('.');
        const key = `${dateParts[2]}-${dateParts[1]}`;
        groupByMonthAndYear[key] ? groupByMonthAndYear[key].push(service) : (groupByMonthAndYear[key] = [service]);
      });

      return Object.keys(groupByMonthAndYear).map(servicesYearMonth => {
        const dateParts = servicesYearMonth.split('-');
        return {
          name: `${MONTHS[dateParts[1] - 1]} ${dateParts[0]}`,
          value: servicesYearMonth + '-01',
        };
      });
    },
  },
  created() {
    this.selectedMonth = this.servicesMonths[0].value;
  },

  methods: {
    getEvents({ start, end }) {
      console.log(start, end);
      let monthEvents = this.services;

      // .filter(service => {
      //   const serviceMonth = getMonth(service.date);
      //   return serviceMonth === start.month;
      // });
      const events = [];
      monthEvents.map(service => {
        const first = new Date(service.date.split('.').reverse().join('-') + ' ' + service.time);

        const second = new Date(first.getTime() + service.hours_per_day);

        events.push({
          service: service,
          name: service.category.name,
          start: first,
          end: second,
          color: '#0C76DA',
          timed: true,
        });
      });

      this.events = events;
    },
    onClickEvent({ event }) {
      const { CANCELED } = this.$ENUM.REQUEST_STATUSES;
      if ([CANCELED].includes(event.service.status)) return;
      this.$emit('edit-child', event.service.id);
    },
  },
};
</script>

<style scoped lang="scss">
//.theme--red {
//  .v-calendar .v-event, .v-calendar .v-event-more {
//    background-color: #FF6B69;
//    border-color: #FF6B69;
//    &:hover {
//      background-color: #D7221F;
//      border-color: #D7221F;
//    }
//  }
//}

::v-deep {
  .theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside{
    opacity: 0.4;
  }
 .v-calendar-weekly__day{
   height: 85px;
   &:first-child{
     border-left: 1px solid #e0e0e0;
   }
}

  .v-calendar-weekly__head-weekday{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    padding: 4px 0px;
    color: #AEAEB2  !important;
    border: none !important;
    border-bottom:  1px solid #EEEEEF !important;
    background: none !important;
  }

  .v-input .v-input__control .v-input__slot{
    border-bottom: none;
  }
  .v-select__selection--comma{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .v-input .v-input__control .v-input__slot .v-select__slot {
    margin-top: 0;
    min-height: 52px;
  }

  .v-input .v-input__control .v-input__slot {
    align-items: center;
    margin-bottom: 0;
  }

  .v-text-field .v-input__append-inner, .v-text-field .v-input__prepend-inner {
    align-items: center;
    margin-top: 0;
    min-height: 52px;
  }

  .v-input .v-input__control .v-input__slot .v-select__slot .v-input__append-inner {
    margin-top: 0;
  }
}
.calendar{
   border-top: none;
   border-left: none;
   margin-top: 20px;
}

.calendar-label{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: #000000;
}

.event{
  height: 100%;
  padding: 8px 10px 11px;

  &__disabled {
    background-color: #666;
  }

  &--CPD {
    background-color: #35C759;
  }

  &--FSD {
    background-color: #BDBDBD;
  }

  &--CNC {
    background-color: #FF3431;
  }
}

.event__time{
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}

.event__name{
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  font-size: 13px;
  line-height: 16px;
}

.top-row{
  min-height: 52px;
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.month-select{
  max-width: 200px;
}

.child-service-open-btn{
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;

}
.slide-content{
   ::v-deep .v-expansion-panel-content__wrap {
    padding: 0px;
    padding-bottom: 15px;

  }
}
.slide-panel{
  &::before{
    box-shadow: none
  }
}

::v-deep .v-input__control {
  margin-bottom: 0;
}

</style>
