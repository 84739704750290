<template>
  <div
    class="card-executor"
    :class="{
      'card-executor--active': isSelected,
    }"
    @click="$emit('click')"
  >
    <div class="card-executor__avatar">
      <img :src="photo" class="card-executor__img" alt="">
    </div>
    <div class="card-executor__content">
      <div class="card-executor__name">{{ name }}</div>
      <div class="card-executor__role">{{ role }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardExecutor",
  props: {
    name: String,
    role: String,
    photo: String,
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">

// card-executor
.card-executor {
  display: block;
  padding: 12px 10px 14px;
  border-radius: 14px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  transition: all .2s ease-in-out;
  cursor: pointer;
  &:hover,
  &--active {
    border: 1px solid var(--color-prime);
  }
  .card-executor__avatar {
    margin: 0 auto 12px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    .card-executor__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .card-executor__content {
    text-align: center;
    .card-executor__name {
      margin-bottom: 4px;
      color: #020202;
      font-weight: 600;
      font-size: 15px;
    }
    .card-executor__role {
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
    }
  }
}
</style>
