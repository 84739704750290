<template>
  <div class="application-content">
    <div class="application-content__num">
      Номер заявки <span>№{{ request.id }}</span>
    </div>

    <BaseCard
      class="help-request-person"
      :item="request"
      :person="helpReqestPerson"
      :description="request.about"
    >
      <template #body="{ item }">
        <div class="attrs-row">
          <CardItem class="attrs-row__item" :underline-top="false">
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
            <strong> {{ item.category.name }} </strong>: {{ item.responsibility && item.responsibility.name }}
          </CardItem>
          <CardItem class="attrs-row__item" :underline-top="false">
            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
            <strong>Стоимость</strong>: {{ item.price }} руб./день
          </CardItem>
        </div>

        <CardItem>
          <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
          <strong>Адрес</strong>: {{ item.address.address }}
        </CardItem>
      </template>
    </BaseCard>

    <CompanyExecutorPerson
      v-if="companyExecutorPerson"
      :person="companyExecutorPerson"
    />

    <HelpNeedPerson
      :persone="helpNeedPerson"
      :service="request"
      :checkIsOwnerResponse="checkIsOwnerResponse"
      @changeStatusChildRequest="event => this.$emit('changeStatusChildRequest', event)"
    ></HelpNeedPerson>

    <template v-if="provider === 'COMPANY'" >
      <ChooseExecutor
        v-if="!isUserAlreadyResponse"
        :executors="executors"
        @set-executor="setExecutor"
      />
      <SetExecutorBtn
        v-if="request.status !== $ENUM.REQUEST_STATUSES.IN_WORK"
        :request="request"
        :executor-id="executorId"
        :is-user-already-response="isUserAlreadyResponse"
        @take-work-success="$emit('take-work')"
      />
    </template>
    <TakeWorkBtn
      v-if="
        request.status === $ENUM.REQUEST_STATUSES.ACTIVE &&
        provider !== 'COMPANY' &&
        isRelativeRequest
      "
      :request="request"
      :is-user-already-response="isUserAlreadyResponse"
      @take-work-success="$emit('take-work')"
    />
    <a
      v-if="request.status === $ENUM.REQUEST_STATUSES.IN_WORK"
      :href="`tel:${request.family.mobile}`"
      class="btn btn--prime take-work-btn phone"
    >
      {{request.family.mobile}}
    </a>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardItem from '@component/Module/PersoneCard/CardItem';
import HelpNeedPerson from '@component/Module/HelpNeedPerson.vue';
import CompanyExecutorPerson from '@component/Module/CompanyExecutorPerson.vue';
import BlockPersonModel from '@component/Module/BlockPersonModel.js';
import HelpNeedPersonModel from '@component/Module/HelpNeedPersonModel.js';
import TakeWorkBtn from '@component/Page/LkAssistanceProvideApplication/TakeWorkBtn.vue';
import BaseCard from '@component/Module/PersoneCard/BaseCard.vue';
import ChooseExecutor from './ChooseExecutor.vue';
import SetExecutorBtn from './SetExecutorBtn.vue';

export default {
  components: {
    HelpNeedPerson,
    CompanyExecutorPerson,
    CardItem,
    BaseCard,
    TakeWorkBtn,
    ChooseExecutor,
    SetExecutorBtn,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    checkIsOwnerResponse: {
      type: Boolean,
      default: false
    },
    provider: {
      type: String,
    },
  },
  data() {
    return {
      executors: [],
      executorId: null,
    };
  },
  computed: {
    ...mapState({
      genders: state => state.enums.genders,
      user: state => state.user.user,
    }),
    isRelativeRequest() {
      return !!this.request.child.length;
    },
    helpNeedPerson() {
      return new HelpNeedPersonModel(this.request.family, this.genders);
    },
    helpReqestPerson() {
      return new BlockPersonModel({
        first_name: this.request.creator.first_name,
        last_name: this.request.creator.last_name,
        photo: this.request.creator.photo,
        fullDate: this.request.created_at,
      });
    },
    companyExecutorPerson() {
      if (!Object.keys(this.request.accepted_response).length) {
        return;
      }
      const person = new BlockPersonModel({
        first_name: this.request.accepted_response.user.first_name,
        last_name: this.request.accepted_response.user.last_name,
        photo: this.request.accepted_response.user.photo,
        fullDate: this.request.accepted_response.accepted_date,
      });

      return {
        ...person,
        birthday: this.request.accepted_response.user.birthday,
        category: this.request.category.name,
      };
    },
    isUserAlreadyResponse() {
      if (!this.request.response) {
        return false;
      }
      const userResp = this.request.response.find(el => {
        const hasExecutorResponse = this.executors.find(executor => executor.to_user.id === el.user.id);
        const hasOwnResponse = this.user.id === el.user.id;
        return hasExecutorResponse || hasOwnResponse;
      });
      return userResp ? true : false;
    },
  },
  created() {
    if (this.provider === 'COMPANY') {
      this.getExecutors();
    }
  },
  methods: {
    async getExecutors() {
      const result = await RequestManager.Invite.getExecutors();
      const executors = result.results.filter(executor => {
        if (!executor?.to_user) return;
        const candidates = [executor.to_user?.id, executor.id];
        const isCreatorExecutor = candidates.includes(this.request.creator?.id);
        return !isCreatorExecutor;
      });
      this.executors = executors;
    },
    setExecutor(executorId) {
      this.executorId = executorId;
    },
  },
};
</script>

<style lang="scss" scoped>
.help-request-person {
   border-bottom: 1px solid rgba(163, 163, 163, 0.3);
   margin-bottom: 36px;
}
.application-content {
  position: relative;
  .request-status {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .application-content__num {
    margin-bottom: 9px;
    color: rgba(2, 2, 2, 0.3);
    font-size: 15px;
    span {
      color: #020202;
    }
  }
}

.attrs-row{
  display: flex;
  justify-content:flex-start;
  flex-direction: column;
}
.attrs-row__item{
  &:not(:last-child){
    border-top: 1px solid rgba(163, 163, 163, 0.3);
  }
  width: 100%;
}

.phone {
  max-width: 343px;
  margin: 0 auto;
}

@include respond-to('md') {
  .attrs-row{
    border-top: 1px solid rgba(163, 163, 163, 0.3);
    justify-content: space-between;
    flex-direction: row;
  }
  .attrs-row__item{
    &:not(:last-child){
      border-top: none;  }
  }
}

</style>
