<template>
  <div>
    <div class="card-request__head">
      <BlockPerson :person="person" />
      <ApplicationStatus
        :status="item.status"
      ></ApplicationStatus>
    </div>
    <div class="card-request__body">
      <slot :item="item" name="body" />
      <div
        v-if="description"
        class="card-request__descr">
        {{ description }}
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import BlockPerson from '@component/Module/BlockPerson';
import ApplicationStatus from "@component/UI/ApplicationStatus";

export default {
  components: {
    ApplicationStatus,
    BlockPerson,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    person: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
// card-request

  .card-request__head {
    position: relative;
    padding: 10px 0px;
    .request-status {
      position: absolute;
      top: 12px;
      right: 30px;
    }
  }
  .card-request__body {
    // padding: 0 15px;
  }
  .card-request__descr {
    padding: 10px 0;
    color: rgba(0, 0, 0, 0.4);
    font-size: 15px;
  }

</style>
