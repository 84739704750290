<template>
  <div>
    <div
      class="card-request-item"
      :class="[
        underline ? 'card-request-item_underline' : '',
        underlineTop ? 'card-request-item_underline_top' : '',
        'card-request-item_' + size,
      ]"
    >
      <div class="ic-ell">
        <div class="ic-ell__icon">
          <div class="ic-ell__svg card-request-item__svg-wrapper">
            <slot name="icon"></slot>
          </div>
        </div>

        <div class="ic-ell__text">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    underline: {
      type: Boolean,
      default: true,
    },
    underlineTop: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.card-request-item {

  &_underline {
    border-bottom: 1px solid rgba(163, 163, 163, 0.3);
    &_top{
      border-top: 1px solid rgba(163, 163, 163, 0.3);
    }
  }
  &_md{
    padding: 8px 0px;
  }
  &_lg{
    padding: 16px 0px;
  }
}

.card-request-item__svg-wrapper {
  display: contents;
}
</style>
